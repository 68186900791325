import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Guidelines'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guidelines`}</h1>
    <p>{`Arwes applications user interface and user experience guidelines. It is a living
guide. These are the core concepts and ideas behind the project which are implemented
in the framework tools.`}</p>
    <p>{`Since a futuristic science fiction application user interface is normally
rich in design, animations, and sounds features, the Arwes guidelines are
broad specifications about the logic and patterns behind the framework tools.
But they are not intended as strict technical or visual specifications for the
tools themselves, more like actual guidelines to base the user interfaces and
user experiences in such kinds of applications.`}</p>
    <h2>{`Functional`}</h2>
    <p>{`An Arwes application can be modeled as a `}<a parentName="p" {...{
        "href": "/project/guidelines/systems"
      }}><strong parentName="a">{`system`}</strong></a>{`,
a tree of dynamically interconnected nodes, where each node can have `}<a parentName="p" {...{
        "href": "/project/guidelines/states"
      }}><strong parentName="a">{`states`}</strong></a>{`
to determine its features and configurations, and they transition from one state
to another based on certain `}<a parentName="p" {...{
        "href": "/project/guidelines/events"
      }}><strong parentName="a">{`application and user events`}</strong></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      